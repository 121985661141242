import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice.js";
import { MdExpandMore, MdLogout } from "react-icons/md";
import logo from "../assets/img/logo-singkalan.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());

    navigate("/");
  };
  return (
    <div>
      <nav id="navbar-main" className="navbar is-fixed-top">
        <div className="navbar-brand">
          <a className="navbar-item" href="#">
            {/* Desa<b>Singkalan</b> */}
            <img src={logo} width="150" height="28"></img>
          </a>
        </div>
        <div className="navbar-brand is-right">
          <a
            className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
            data-target="navbar-menu"
          >
            <span className="icon">
              <i className="mdi mdi-dots-vertical"></i>
            </span>
          </a>
        </div>
        <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider is-hoverable">
              <a className="navbar-link is-arrowless">
                <span className="icon">
                  <i className="mdi mdi-menu"></i>
                </span>
                <span>{user && user.name}</span>
                <span className="icon">
                  <MdExpandMore />
                </span>
              </a>
              <div className="navbar-dropdown">
                <a onClick={logout} className="navbar-item">
                  <span className="icon">
                    <MdLogout />
                  </span>
                  <span>Log Out</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
