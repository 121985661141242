import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormAddKependudukan = () => {
  const [kk, setKk] = useState("");
  const [nik, setNik] = useState("");
  const [nama, setNama] = useState("");
  const [dusun, setDusun] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [shdk, setShdk] = useState("");
  const [jk, setJk] = useState("");
  const [tl, setTl] = useState("");
  const [tgl, setTgl] = useState("");
  const [umur, setUmur] = useState("");
  const [agama, setAgama] = useState("");
  const [pendidikanTamat, setPendidikanTamat] = useState("");
  const [pendidikanSkrg, setPendidikanSkrg] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [penghasilan, setPenghasilan] = useState("");
  const [statusPerkawinan, setPerkawinan] = useState("");
  const [jaminanKesehatan, setJaminanKes] = useState("");
  const [namaIbu, setNamaIbu] = useState("");
  const [namaAyah, setNamaAyah] = useState("");
  const [statusYatim, setStatusYatim] = useState("");
  const [nohp, setNohp] = useState("");
  const [email, setEmail] = useState("");
  const [bantuan, setBantuan] = useState("");
  const [pengeluaran, setPengeluaran] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [customPekerjaan, setCustomPekerjaan] = useState("");

  const handleCheckboxChange = (value) => {
    if (bantuan.includes(value)) {
      setBantuan(bantuan.filter((item) => item !== value));
    } else {
      setBantuan([...bantuan, value]);
    }
  };

  const bantuanOptions = [
    { value: "BPNT APBD", label: "BPNT APBD" },
    { value: "BPNT APBN", label: "BPNT APBN" },
    { value: "PKH", label: "PKH" },
    { value: "BLT DD", label: "BLT DD" },
    { value: "KIS", label: "KIS" },
  ];

  const handlePekerjaanChange = (e) => {
    const selectedPekerjaan = e.target.value;
    setPekerjaan(selectedPekerjaan);

    if (selectedPekerjaan !== "LAINNYA") {
      setCustomPekerjaan("");
    }
  };

  const handleCustomPekerjaanChange = (e) => {
    const value = e.target.value;
    setCustomPekerjaan(value);
  };

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthdateObj = new Date(birthdate);
    let age = today.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = today.getMonth() - birthdateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdateObj.getDate())
    ) {
      age--;
    }
    return age;
  };

  const handleTglLahirChange = (e) => {
    const birthdate = e.target.value;
    setTgl(birthdate);
    const age = calculateAge(birthdate);
    setUmur(age);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pekerjaan === "LAINNYA") {
      setPekerjaan(customPekerjaan);
    }
    try {
      const confirmed = window.confirm(
        "Anda yakin untuk menyimpan data?"
      );
      if (confirmed) {
        await axios.post("https://api.desasingkalan.com/penduduk", {
          kk: kk,
          nik: nik,
          nama: nama,
          dusun: dusun,
          rt: rt,
          rw: rw,
          shdk: shdk,
          jenis_kelamin: jk,
          tempat_lahir: tl,
          tgl_lahir: tgl,
          umur: umur,
          agama: agama,
          pendidikan_tamat: pendidikanTamat,
          pendidikan_skrg: pendidikanSkrg,
          pekerjaan: pekerjaan,
          penghasilan: penghasilan,
          status_perkawinan: statusPerkawinan,
          jaminan_kesehatan: jaminanKesehatan,
          nama_ibu: namaIbu,
          nama_ayah: namaAyah,
          status_yatim: statusYatim,
          nohp: nohp,
          email: email,
          bantuan: bantuan,
          pengeluaran: pengeluaran,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/kependudukan");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };

  return (
    <div>
      <h1 className="title">Data Penduduk</h1>
      <h2 className="subtitle">Add Data Penduduk</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="columns">
                <div className="column">
                  <InputKK label="No KK" value={kk} onChange={setKk} />
                </div>
                <div className="column">
                  <InputKK label="NIK" value={nik} onChange={setNik} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nama</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        placeholder="Nama"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Dusun</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={dusun}
                          onChange={(e) => setDusun(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Dusun
                          </option>
                          <option value="TADO">TADO</option>
                          <option value="SINGKALAN">SINGKALAN</option>
                          <option value="BANJARANYAR">BANJARANYAR</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">RT</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rt}
                          onChange={(e) => setRt(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RT
                          </option>
                          {Array.from({ length: 16 }, (_, index) => {
                            const rtNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rtNumber} value={rtNumber}>
                                {rtNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">RW</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rw}
                          onChange={(e) => setRw(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RW
                          </option>
                          {Array.from({ length: 6 }, (_, index) => {
                            const rwNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rwNumber} value={rwNumber}>
                                {rwNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">SHDK</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={shdk}
                          onChange={(e) => setShdk(e.target.value)}
                        >
                          <option value="" disabled>
                            Select SHDK
                          </option>
                          <option value="KEPALA KELUARGA">
                            KEPALA KELUARGA
                          </option>
                          <option value="ISTRI">ISTRI</option>
                          <option value="ANAK">ANAK</option>
                          <option value="MERTUA">MERTUA</option>
                          <option value="ORANG TUA">ORANG TUA</option>
                          <option value="MENANTU">MENANTU</option>
                          <option value="MENANTU">CUCU</option>
                          <option value="FAMILI LAIN">FAMILI LAIN</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Jenis Kelamin</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={jk}
                          onChange={(e) => setJk(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Jenis Kelamin
                          </option>
                          <option value="LAKI-LAKI">LAKI-LAKI</option>
                          <option value="PEREMPUAN">PEREMPUAN</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Tempat Lahir</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={tl}
                        onChange={(e) => setTl(e.target.value)}
                        placeholder="Tempat Lahir"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Tgl Lahir</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={tgl}
                        onChange={handleTglLahirChange}
                        placeholder="Tgl Lahir"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Umur</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={umur}
                        onChange={(e) => setUmur(e.target.value)}
                        placeholder="Umur"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Agama</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={agama}
                          onChange={(e) => setAgama(e.target.value)}
                        >
                          <option value="" disabled>
                            Pilih Agama
                          </option>
                          <option value="ISLAM">ISLAM</option>
                          <option value="KRISTEN">KRISTEN</option>
                          <option value="KATOLIK">KATOLIK</option>
                          <option value="PROTESTAN">PROTESTAN</option>
                          <option value="HINDU">HINDU</option>
                          <option value="BUDDHA">BUDDHA</option>
                          <option value="KONGHUCU">KONGHUCU</option>
                          <option value="KEPERCAYAAN LAINNYA">
                            KEPERCAYAAN LAINNYA
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Pendidikan Terakhir</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={pendidikanTamat}
                          onChange={(e) => setPendidikanTamat(e.target.value)}
                        >
                          <option value="" disabled>
                            Pilih Pendidikan
                          </option>
                          <option value="TIDAK SEKOLAH">TIDAK SEKOLAH</option>
                          <option value="SD">SD</option>
                          <option value="SLTP">SLTP</option>
                          <option value="SLTA">SLTA</option>
                          <option value="D1">D1</option>
                          <option value="D2">D2</option>
                          <option value="D3">D3</option>
                          <option value="S1">S1</option>
                          <option value="S2">S2</option>
                          <option value="S3">S3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Pendidikan Sedang Ditempuh</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={pendidikanSkrg}
                          onChange={(e) => setPendidikanSkrg(e.target.value)}
                        >
                          <option value="" disabled>
                            Pilih Pendidikan
                          </option>
                          <option value="TIDAK SEKOLAH">TIDAK SEKOLAH</option>
                          <option value="SD">SD</option>
                          <option value="SLTP">SLTP</option>
                          <option value="SLTA">SLTA</option>
                          <option value="D1">D1</option>
                          <option value="D2">D2</option>
                          <option value="D3">D3</option>
                          <option value="S1">S1</option>
                          <option value="S2">S2</option>
                          <option value="S3">S3</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Pekerjaan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={pekerjaan}
                          onChange={handlePekerjaanChange}
                        >
                          <option value="" disabled>
                            Pilih Pekerjaan
                          </option>
                          <option value="BELUM/TIDAK BEKERJA">
                            BELUM/TIDAK BEKERJA
                          </option>
                          <option value="MENGURUS RUMAH TANGGA">
                            MENGURUS RUMAH TANGGA
                          </option>
                          <option value="KARYAWAN SWASTA">
                            KARYAWAN SWASTA
                          </option>
                          <option value="WIRASWASTA">WIRASWASTA</option>
                          <option value="PEDAGANG">PEDAGANG</option>
                          <option value="KEPALA DESA">KEPALA DESA</option>
                          <option value="PERANGKAT DESA">PERANGKAT DESA</option>
                          <option value="PETANI/PEKEBUN">PETANI/PEKEBUN</option>
                          <option value="TUKANG BANGUNAN">
                            TUKANG BANGUNAN
                          </option>
                          <option value="KULI BANGUNAN">KULI BANGUNAN</option>
                          <option value="BURUH HARIAN LEPAS">
                            BURUH HARIAN LEPAS
                          </option>
                          <option value="DOKTER">DOKTER</option>
                          <option value="PEGAWAI NEGERI SIPIL">
                            PEGAWAI NEGERI SIPIL
                          </option>
                          <option value="BIDAN">BIDAN</option>
                          <option value="POLRI">POLRI</option>
                          <option value="TNI">TNI</option>
                          <option value="PENSIUNAN">PENSIUNAN</option>
                          <option value="PELAJAR/MAHASISWA">
                            PELAJAR/MAHASISWA
                          </option>
                          <option value="SOPIR">SOPIR</option>
                          <option value="GURU">GURU</option>
                          <option value="LAINNYA">LAINNYA</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Penghasilan</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={penghasilan}
                        onChange={(e) => setPenghasilan(e.target.value)}
                        placeholder="Penghasilan"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {pekerjaan === "LAINNYA" && (
                <div className="field">
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={customPekerjaan}
                      onChange={handleCustomPekerjaanChange}
                      placeholder="Masukkan Pekerjaan Lainnya"
                    />
                  </div>
                </div>
              )}

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Status Perkawinan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={statusPerkawinan}
                          onChange={(e) => setPerkawinan(e.target.value)}
                        >
                          <option value="" disabled>
                            Pilih Status Perkawinan
                          </option>
                          <option value="BELUM KAWIN">BELUM KAWIN</option>
                          <option value="KAWIN">KAWIN</option>
                          <option value="CERAI MATI">CERAI MATI</option>
                          <option value="CERAI HIDUP">CERAI HIDUP</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Jaminan Kesehatan</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={jaminanKesehatan}
                          onChange={(e) => setJaminanKes(e.target.value)}
                        >
                          <option value="" disabled>
                            Pilih Jaminan Kesehatan
                          </option>
                          <option value="PESERTA">PESERTA</option>
                          <option value="TIDAK PESERTA">TIDAK PESERTA</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Ibu</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={namaIbu}
                        onChange={(e) => setNamaIbu(e.target.value)}
                        placeholder="Nama Ibu"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Ayah</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={namaAyah}
                        onChange={(e) => setNamaAyah(e.target.value)}
                        placeholder="Nama Ayah"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Status Yatim</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={statusYatim}
                      onChange={(e) => setStatusYatim(e.target.value)}
                    >
                      <option value="" disabled>
                        Pilih Status Yatim
                      </option>
                      <option value="TIDAK">TIDAK</option>
                      <option value="YATIM">YATIM</option>
                      <option value="PIATU">PIATU</option>
                      <option value="YATIM PIATU">YATIM PIATU</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">No HP</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nohp}
                        onChange={(e) => setNohp(e.target.value)}
                        placeholder="No HP"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Bantuan</label>
                    <div className="control">
                      {bantuanOptions.map((option) => (
                        <label key={option.value} className="checkbox ml-3">
                          <input
                            type="checkbox"
                            checked={bantuan.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                            className="mr-1"
                          />
                          {option.label}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Pengeluaran</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={pengeluaran}
                        onChange={(e) => setPengeluaran(e.target.value)}
                        placeholder="Pengeluaran"
                        disabled={shdk !== "KEPALA KELUARGA"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddKependudukan;
