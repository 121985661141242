import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MdOutlineAdd,
  MdModeEditOutline,
  MdDelete,
  MdOutput,
} from "react-icons/md";

const KependudukanList = () => {
  const [kependudukan, setKependudukan] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [kkValue, setKKValue] = useState("");
  const [nikValue, setNIKValue] = useState("");
  const [namaValue, setNamaValue] = useState("");
  const [rtValue, setRTValue] = useState("");
  const [umurValue, setUmurValue] = useState("");
  const [pekerjaanValue, setPekerjaanValue] = useState("");
  const [statusYatimValue, setStatusYatimValue] = useState("");
  const [jandaDudaValue, setJandaDudaValue] = useState("");

  useEffect(() => {
    getKependudukan();
  }, [currentPage]);

  const deleteProduct = async (pendudukId) => {
    const confirmed = window.confirm(
      "Apakah anda yakin untuk menghapus data ini?"
    );
    if (confirmed) {
      // Perform the delete operation
      await axios.delete(
        `https://api.desasingkalan.com/penduduk/${pendudukId}`
      );
      getKependudukan();
    }
  };

  const moveKematian = async (pendudukId) => {
    const confirmed = window.confirm("Pindah ke Data Kematian?");
    if (confirmed) {
      await axios.patch(
        `https://api.desasingkalan.com/movekematian/${pendudukId}`
      );
      getKependudukan();
    }
  };

  const getKependudukan = async () => {
    try {
      const response = await axios.get(
        `https://api.desasingkalan.com/kependudukan?page=${currentPage}`
      );
      const { data, totalPages } = response.data;
      setKependudukan(data);
      setTotalPages(totalPages);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setCurrentPage(1);
    setKependudukan([]); // Reset the state before making a new search

    try {
      let response;
      let searchParam = "";

      // Construct the search parameter based on the input values
      if (kkValue) {
        searchParam = `kk/${kkValue}`;
      } else if (nikValue) {
        searchParam = `nik/${nikValue}`;
      } else if (namaValue) {
        searchParam = `nama/${namaValue}`;
      } else if (rtValue) {
        searchParam = `rt/${rtValue}`;
      } else if (umurValue) {
        searchParam = `umur/${umurValue}`;
      } else if (pekerjaanValue) {
        searchParam = `pekerjaan/${pekerjaanValue}`;
      } else if (statusYatimValue) {
        searchParam = `statusyatim/${statusYatimValue}`;
      } else if (jandaDudaValue) {
        searchParam = `statusjandaduda/${jandaDudaValue}`;
      } else {
        return; // No search option selected
      }

      // Make the API request using the constructed URL
      response = await axios.get(
        `https://api.desasingkalan.com/penduduk/${searchParam}`
      );

      // Update the state with the search results
      setKependudukan(response.data);
      // After setting the search results, update the totalPages state
      const searchResults = response.data;
      const itemsPerPage = 10; // Assuming 10 items displayed per page, you can adjust this based on your requirement
      const totalPages = Math.ceil(searchResults.length / itemsPerPage);
      setTotalPages(totalPages <= 1 ? 1 : totalPages);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setKependudukan([]);
        const errorMessage = error.response.data.msg;
        console.log("Data not found:", errorMessage);
        setKependudukan([{ msg: errorMessage }]);
      } else {
        console.log("Error:", error);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const maxVisiblePages = 5;
    const totalPagesToShow = Math.min(totalPages, maxVisiblePages);

    if (totalPagesToShow <= 1) return null;

    const pageNumbers = [];
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (startPage > 1) {
      pageNumbers.push(
        <li key={1}>
          <button
            className="pagination-link"
            aria-label="Goto page 1"
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li key="ellipsis-1">
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`pagination-link ${
              currentPage === i ? "is-current" : ""
            }`}
            aria-label={`Goto page ${i}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis-2">
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
      }
      pageNumbers.push(
        <li key={totalPages}>
          <button
            className="pagination-link"
            aria-label={`Goto page ${totalPages}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <section className="section is-title-bar p-1 pb-3">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Data Kependudukan</h1>
            </div>
            <Link to="/kependudukan/add" className="button is-primary">
              <span className="icon">
                <MdOutlineAdd />
              </span>
              <span>Add new</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="section is-main-section p-2 pb-3">
        <div className="card mb-3">
          <div className="card-content">
            <h2 className="title is-5 mb-3">Search</h2>
            <form onSubmit={handleSearch}>
              <div className="columns">
                {/* Column for KK */}
                <div className="column">
                  <div className="field">
                    <label className="label">KK</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter KK"
                        value={kkValue}
                        onChange={(event) => setKKValue(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Column for NIK */}
                <div className="column">
                  <div className="field">
                    <label className="label">NIK</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter NIK"
                        value={nikValue}
                        onChange={(event) => setNIKValue(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Column for Nama */}
                <div className="column">
                  <div className="field">
                    <label className="label">Nama</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter Nama"
                        value={namaValue}
                        onChange={(event) => setNamaValue(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Column for RT */}
                <div className="column">
                  <div className="field">
                    <label className="label">RT</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rtValue}
                          onChange={(event) => setRTValue(event.target.value)}
                        >
                          <option value="">Select RT</option>
                          <option value="001">001</option>
                          <option value="002">002</option>
                          <option value="003">003</option>
                          <option value="004">004</option>
                          <option value="005">005</option>
                          <option value="006">006</option>
                          <option value="007">007</option>
                          <option value="008">008</option>
                          <option value="009">009</option>
                          <option value="010">010</option>
                          <option value="011">011</option>
                          <option value="012">012</option>
                          <option value="013">013</option>
                          <option value="014">014</option>
                          <option value="015">015</option>
                          <option value="016">016</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Column for Umur */}
                <div className="column">
                  <div className="field">
                    <label className="label">Umur</label>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        placeholder="Enter Umur"
                        value={umurValue}
                        onChange={(event) => setUmurValue(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Column for Umur */}
                <div className="column">
                  <div className="field">
                    <label className="label">Pekerjaan</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Enter Pekerjaan"
                        value={pekerjaanValue}
                        onChange={(event) =>
                          setPekerjaanValue(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* Column for Status Yatim */}
                <div className="column">
                  <div className="field">
                    <label className="label">Status Yatim</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={statusYatimValue}
                          onChange={(event) =>
                            setStatusYatimValue(event.target.value)
                          }
                        >
                          <option value="">Select Status Yatim</option>
                          <option value="yatim">Yatim</option>
                          <option value="piatu">Piatu</option>
                          <option value="yatim-piatu">Yatim Piatu</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Column for Status Janda/Duda */}
                <div className="column">
                  <div className="field">
                    <label className="label">Status Janda/Duda</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={jandaDudaValue}
                          onChange={(event) =>
                            setJandaDudaValue(event.target.value)
                          }
                        >
                          <option value="">Select Status Janda/Duda</option>
                          <option value="janda">Janda</option>
                          <option value="duda">Duda</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">&nbsp;</label>
                      <button
                        className="button is-primary is-fullwidth"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card has-table has-table-container-upper-radius is-fullwidth">
          <div className="card-content">
            <div className="table-container">
              <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>No KK</th>
                    <th>NIK</th>
                    <th>Nama</th>
                    <th>Dusun</th>
                    <th>RT</th>
                    <th>RW</th>
                    <th>SHDK</th>
                    <th>Jenis Kelamin</th>
                    <th>Tempat Lahir</th>
                    <th>Tanggal Lahir</th>
                    <th>Umur</th>
                    <th>Agama</th>
                    <th>Pendidikan Terakhir</th>
                    <th>Pendidikan Sedang Ditempuh</th>
                    <th>Pekerjaan</th>
                    <th>Penghasilan</th>
                    <th>Status Perkawinan</th>
                    <th>Jaminan Kesehatan</th>
                    <th>Nama Ibu</th>
                    <th>Nama Ayah</th>
                    <th>Status Yatim</th>
                    <th>No HP</th>
                    <th>Email</th>
                    <th>Bantuan</th>
                    <th>Pengeluaran</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {kependudukan.length === 0 ? (
                    <tr>
                      <td colSpan="28">No data available.</td>
                    </tr>
                  ) : (
                    kependudukan.map((penduduk, index) => {
                      const rowNumber = (currentPage - 1) * 10 + index + 1;
                      if (penduduk.msg) {
                        return (
                          <tr key={index}>
                            <td colSpan="28">{penduduk.msg}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={penduduk.uuid}>
                            <td>{rowNumber}</td>
                            <td>{penduduk.kk}</td>
                            <td>{penduduk.nik}</td>
                            <td>{penduduk.nama}</td>
                            <td>{penduduk.dusun}</td>
                            <td>{penduduk.rt}</td>
                            <td>{penduduk.rw}</td>
                            <td>{penduduk.shdk}</td>
                            <td>{penduduk.jenis_kelamin}</td>
                            <td>{penduduk.tempat_lahir}</td>
                            <td>{formatDate(penduduk.tgl_lahir)}</td>
                            <td>{penduduk.umur}</td>
                            <td>{penduduk.agama}</td>
                            <td>{penduduk.pendidikan_tamat}</td>
                            <td>{penduduk.pendidikan_skrg}</td>
                            <td>{penduduk.pekerjaan}</td>
                            <td>{penduduk.penghasilan}</td>
                            <td>{penduduk.status_perkawinan}</td>
                            <td>{penduduk.jaminan_kesehatan}</td>
                            <td>{penduduk.nama_ibu}</td>
                            <td>{penduduk.nama_ayah}</td>
                            <td>{penduduk.status_yatim}</td>
                            <td>{penduduk.nohp}</td>
                            <td>{penduduk.email}</td>
                            <td>
                              {penduduk.bantuan !== `""`
                                ? penduduk.bantuan
                                : "-"}
                            </td>
                            <td>{penduduk.pengeluaran}</td>
                            <td className="is-actions-cell">
                              <div className="buttons is-right">
                                <Link
                                  to={`/kependudukan/edit/${penduduk.uuid}`}
                                  className="button is-small is-primary"
                                  type="button"
                                >
                                  <span className="icon">
                                    <MdModeEditOutline />
                                  </span>
                                </Link>
                                <button
                                  onClick={() => moveKematian(penduduk.uuid)}
                                  className="button is-small is-warning jb-modal"
                                  data-target="sample-modal"
                                  type="button"
                                >
                                  <span className="icon">
                                    <MdOutput />
                                  </span>
                                </button>
                                <button
                                  onClick={() => deleteProduct(penduduk.uuid)}
                                  className="button is-small is-danger jb-modal"
                                  data-target="sample-modal"
                                  type="button"
                                >
                                  <span className="icon">
                                    <MdDelete />
                                  </span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })
                  )}
                </tbody>
              </table>
            </div>

            <nav
              className="pagination is-centered"
              role="navigation"
              aria-label="pagination"
            >
              <ul className="pagination-list">{renderPageNumbers()}</ul>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KependudukanList;
