import React, { useState } from "react";

const InputKK = ({ label, value, onChange }) => {
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value.slice(0, 16);
    onChange(inputValue);
    setError(inputValue.length === 16 ? "" : "Input kurang dari 16 karakter");
  };

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control">
        <input
          type="number"
          className={`input ${error && "is-danger"}`}
          value={value}
          onChange={handleInputChange}
          placeholder={label}
        />
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  );
};

export default InputKK;
