import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormEditKehamilan = () => {
  const [kk, setKk] = useState("");
  const [nik, setNik] = useState("");
  const [nama, setNama] = useState("");
  const [namaSuami, setNamaSuami] = useState("");
  const [hpl, setHpl] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getKehamilanById = async () => {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/kehamilan/${id}`
        );
        setKk(response.data.kk);
        setNik(response.data.nik);
        setNama(response.data.nama);
        setNamaSuami(response.data.nama_suami);
        const tglDate = new Date(response.data.hpl);
        const tglFormatted = tglDate
          .toISOString()
          .split("T")[0];
        setHpl(tglFormatted);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getKehamilanById();
  }, [id]);

  const updateKehamilan = async (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = {};

    if (!kk) {
      validationErrors.nikPria = "Field tidak boleh kosong";
    }

    if (!nik) {
      validationErrors.namaPria = "Field tidak boleh kosong";
    }

    if (!nama) {
      validationErrors.nikWanita = "Field tidak boleh kosong";
    }

    if (!namaSuami) {
      validationErrors.namaWanita = "Field tidak boleh kosong";
    }

    if (!hpl) {
      validationErrors.wali = "Field tidak boleh kosong";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const confirmed = window.confirm("Anda yakin untuk menyimpan data?");
      if (confirmed) {
        await axios.patch(`https://api.desasingkalan.com/kehamilan/${id}`, {
          kk: kk,
          nik: nik,
          nama: nama,
          nama_suami: namaSuami,
          hpl: hpl,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/kehamilan");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };
  return (
    <div>
      <h1 className="title">Data Kehamilan</h1>
      <h2 className="subtitle">Update data kehamilan</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateKehamilan}>
              <div className="columns">
                <div className="column">
                  <InputKK label="KK" value={kk} onChange={setKk} />
                </div>
                <div className="column">
                  <InputKK label="NIK" value={nik} onChange={setNik} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nama</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.nama && "is-danger"}`}
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        placeholder="Nama"
                      />
                    </div>
                    {errors.nama && (
                      <p className="help is-danger">{errors.nama}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Suami</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaSuami && "is-danger"}`}
                        value={namaSuami}
                        onChange={(e) => setNamaSuami(e.target.value)}
                        placeholder="Nama Suami"
                      />
                    </div>
                    {errors.namaSuami && (
                      <p className="help is-danger">{errors.namaSuami}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">HPL</label>
                    <div className="control">
                      <input
                        type="date"
                        className={`input ${errors.hpl && "is-danger"}`}
                        value={hpl}
                        onChange={(e) => setHpl(e.target.value)}
                        placeholder="HPL"
                      />
                    </div>
                    {errors.hpl && (
                      <p className="help is-danger">{errors.hpl}</p>
                    )}
                  </div>
                </div>
                <div className="column"></div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditKehamilan;
