import { BrowserRouter, Routes, Route} from "react-router-dom"
import Dashboard from "./pages/Dashboard.jsx";
import Login from "./components/Login.jsx";
import Users from "./pages/Users.jsx";
import AddUser from "./pages/AddUser.jsx";
import EditUser from "./pages/EditUser.jsx";
import Kependudukan from "./pages/Kependudukan.jsx";
import AddKependudukan from "./pages/AddKependudukan.jsx";
import EditKependudukan from "./pages/EditKependudukan.jsx";

import Kematian from "./pages/Kematian.jsx";
import AddKematian from "./pages/AddKematian.jsx";
import EditKematian from "./pages/EditKematian.jsx";

import Kelahiran from "./pages/Kelahiran.jsx";
import AddKelahiran from "./pages/AddKelahiran.jsx";
import EditKelahiran from "./pages/EditKelahiran.jsx";

import Kepindahan from "./pages/Kepindahan.jsx";
import AddKepindahan from "./pages/AddKepindahan.jsx";
import EditKepindahan from "./pages/EditKepindahan.jsx";

import Pernikahan from "./pages/Pernikahan.jsx";
import AddPernikahan from "./pages/AddPernikahan.jsx";
import EditPernikahan from "./pages/EditPernikahan.jsx";

import Kehamilan from "./pages/Kehamilan.jsx";
import AddKehamilan from "./pages/AddKehamilan.jsx";
import EditKehamilan from "./pages/EditKehamilan.jsx";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}></Route>
          <Route path="/dashboard" element={<Dashboard/>}></Route>
          <Route path="/users" element={<Users/>}></Route>
          <Route path="/users/add" element={<AddUser/>}></Route>
          <Route path="/users/edit/:id" element={<EditUser/>}></Route>
          
          <Route path="/kependudukan" element={<Kependudukan/>}></Route>
          <Route path="/kependudukan/add" element={<AddKependudukan/>}></Route>
          <Route path="/kependudukan/edit/:id" element={<EditKependudukan/>}></Route>

          <Route path="/kematian" element={<Kematian/>}></Route>
          <Route path="/kematian/add" element={<AddKematian/>}></Route>
          <Route path="/kematian/edit/:id" element={<EditKematian/>}></Route>
        
          <Route path="/kelahiran" element={<Kelahiran/>}></Route>
          <Route path="/kelahiran/add" element={<AddKelahiran/>}></Route>
          <Route path="/kelahiran/edit/:id" element={<EditKelahiran/>}></Route>
        
          <Route path="/kepindahan" element={<Kepindahan/>}></Route>
          <Route path="/kepindahan/add" element={<AddKepindahan/>}></Route>
          <Route path="/kepindahan/edit/:id" element={<EditKepindahan/>}></Route>
        
          <Route path="/pernikahan" element={<Pernikahan/>}></Route>
          <Route path="/pernikahan/add" element={<AddPernikahan/>}></Route>
          <Route path="/pernikahan/edit/:id" element={<EditPernikahan/>}></Route>

          <Route path="/kehamilan" element={<Kehamilan/>}></Route>
          <Route path="/kehamilan/add" element={<AddKehamilan/>}></Route>
          <Route path="/kehamilan/edit/:id" element={<EditKehamilan/>}></Route>
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
