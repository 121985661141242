import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { MdOutlineAdd } from "react-icons/md";
import { MdModeEditOutline, MdDelete } from "react-icons/md";

const PernikahanList = () => {
  const [pernikahan, setPernikahan] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getPernikahan();
  }, [currentPage]);

  const deleteProduct = async (pendudukId) => {
    const confirmed = window.confirm(
      "Apakah anda yakin untuk menghapus data ini?"
    );
    if (confirmed) {
      await axios.delete(`https://api.desasingkalan.com/pernikahan/${pendudukId}`);
      getPernikahan();
    }
  };

  const getPernikahan = async () => {
    try {
      const response = await axios.get(
        `https://api.desasingkalan.com/nikah?page=${currentPage}`
      );
      const { data, totalPages } = response.data;
      setPernikahan(data);
      setTotalPages(totalPages);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    if (searchType === "NIK") {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/pernikahan/nik/${searchQuery}`
        );
        setPernikahan([response.data]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPernikahan([]);
          const errorMessage = error.response.data.msg;
          console.log("Data not found:", errorMessage);
          setPernikahan([{ msg: errorMessage }]);
        } else {
          console.log("Error:", error);
        }
      }
    } else if (searchType === "Nama") {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/pernikahan/nama/${searchQuery}`
        );
        setPernikahan([response.data]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPernikahan([]);
          const errorMessage = error.response.data.msg;
          console.log("Data not found:", errorMessage);
          setPernikahan([{ msg: errorMessage }]);
        } else {
          console.log("Error:", error);
        }
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const maxVisiblePages = 5;
    const totalPagesToShow = Math.min(totalPages, maxVisiblePages);

    if (totalPagesToShow <= 1) return null;

    const pageNumbers = [];
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (startPage > 1) {
      pageNumbers.push(
        <li key={1}>
          <button
            className="pagination-link"
            aria-label="Goto page 1"
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li key="ellipsis-1">
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            className={`pagination-link ${
              currentPage === i ? "is-current" : ""
            }`}
            aria-label={`Goto page ${i}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis-2">
            <span className="pagination-ellipsis">&hellip;</span>
          </li>
        );
      }
      pageNumbers.push(
        <li key={totalPages}>
          <button
            className="pagination-link"
            aria-label={`Goto page ${totalPages}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <section className="section is-title-bar p-1 pb-3">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Data Pernikahan</h1>
            </div>
            <Link to="/pernikahan/add" className="button is-primary">
              <span className="icon">
                <MdOutlineAdd />
              </span>
              <span>Add new</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="section is-main-section p-2 pb-3">
        <div className="card mb-3">
          <div className="card-content">
            <h2 className="title is-5 mb-3">Search</h2>
            <form onSubmit={handleSearch}>
              <div className="field has-addons">
                <div className="control">
                  <div className="select">
                    <select
                      value={searchType}
                      onChange={(event) => setSearchType(event.target.value)}
                    >
                      <option value="" disabled defaultValue>
                        Search By
                      </option>
                      <option value="NIK">NIK Pria</option>
                      <option value="Nama">Nama Pria</option>
                    </select>
                  </div>
                </div>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Search by NIK/Nama"
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    disabled={!searchType}
                  />
                </div>
                <div className="control">
                  <button className="button is-primary" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="card has-table has-table-container-upper-radius is-fullwidth">
          <div className="card-content">
            <div className="b-table has-pagination">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>NIK Pria</th>
                      <th>Nama Pria</th>
                      <th>NIK Wanita</th>
                      <th>Nama Wanita</th>
                      <th>Wali</th>
                      <th>Tgl Pernikahan</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {pernikahan.length === 0 ? (
                      <tr>
                        <td colSpan="8">No data available.</td>
                      </tr>
                    ) : (pernikahan.map((penduduk, index) => {
                      const rowNumber = (currentPage - 1) * 10 + index + 1;
                      if (penduduk.msg) {
                        return (
                          <tr key={index}>
                            <td colSpan="8">{penduduk.msg}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={penduduk.uuid}>
                            <td>{rowNumber}</td>
                            <td>{penduduk.nik_pria}</td>
                            <td>{penduduk.nama_pria}</td>
                            <td>{penduduk.nik_wanita}</td>
                            <td>{penduduk.nama_wanita}</td>
                            <td>{penduduk.wali}</td>
                            <td>{formatDate(penduduk.tgl_pernikahan)}</td>
                            <td className="is-actions-cell">
                              <div className="buttons is-right">
                                <Link
                                  to={`/pernikahan/edit/${penduduk.uuid}`}
                                  className="button is-small is-primary"
                                  type="button"
                                >
                                  <span className="icon">
                                    <MdModeEditOutline />
                                  </span>
                                </Link>
                                <button
                                  onClick={() => deleteProduct(penduduk.uuid)}
                                  className="button is-small is-danger jb-modal"
                                  data-target="sample-modal"
                                  type="button"
                                >
                                  <span className="icon">
                                    <MdDelete />
                                  </span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }))}
                  </tbody>
                </table>
              </div>
            </div>
            <nav
              className="pagination is-centered"
              role="navigation"
              aria-label="pagination"
            >
              <ul className="pagination-list">{renderPageNumbers()}</ul>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PernikahanList;
