import React, {useEffect} from "react";
import Layout from "./Layout.jsx";
import FormEditPernikahan from "../components/FormEditPernikahan.jsx";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../features/authSlice.js'

const EditPernikahan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);
  return (
    <Layout>
      <FormEditPernikahan />
    </Layout>
  );
};

export default EditPernikahan;
