import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { MdOutlineAdd, MdDelete, MdModeEditOutline } from "react-icons/md";

const Userlist = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUsers = async (userId) => {
    await axios.delete(`https://api.desasingkalan.com/users/${userId}`);
    getUsers();
  };

  const getUsers = async () => {
    const response = await axios.get("https://api.desasingkalan.com/users");
    setUsers(response.data);
  };
  return (
    <div>
      <section className="section is-title-bar p-1 pb-3">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Users</h1>
            </div>
            <Link to="/users/add" className="button is-primary">
              <span className="icon">
                <MdOutlineAdd />
              </span>
              <span>Add new</span>
            </Link>
          </div>
        </div>
      </section>
      <section className="section is-main-section p-2 pb-3">
        <div className="card has-table has-table-container-upper-radius is-fullwidth">
          <div className="card-content">
            <div className="b-table has-pagination">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user.uuid}>
                        <td>{index + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td className="is-actions-cell">
                          <div className="buttons is-right">
                            <Link
                              to={`/users/edit/${user.uuid}`}
                              className="button is-small is-primary"
                              type="button"
                            >
                              <span className="icon">
                                <MdModeEditOutline/>
                              </span>
                            </Link>
                            <button
                              onClick={() => deleteUsers(user.uuid)}
                              className="button is-small is-danger jb-modal"
                              data-target="sample-modal"
                              type="button"
                            >
                              <span className="icon">
                                <MdDelete/>
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Userlist;
