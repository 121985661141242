import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormAddKelahiran = () => {
  const [namaBayi, setNamaBayi] = useState("");
  const [namaAyah, setNamaAyah] = useState("");
  const [namaIbu, setNamaIbu] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [beratBayi, setBeratBayi] = useState("");
  const [panjangBayi, setPanjangBayi] = useState("");
  const [dusun, setDusun] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = {};

    if (!namaBayi) {
      validationErrors.namaBayi = "Field tidak boleh kosong";
    }

    if (!namaAyah) {
      validationErrors.namaAyah = "Field tidak boleh kosong";
    }

    if (!namaIbu) {
      validationErrors.namaIbu = "Field tidak boleh kosong";
    }

    if (!tempatLahir) {
      validationErrors.tempatLahir = "Field tidak boleh kosong";
    }

    if (!tglLahir) {
      validationErrors.tglLahir = "Field tidak boleh kosong";
    }

    if (!beratBayi) {
      validationErrors.beratBayi = "Field tidak boleh kosong";
    }

    if (!panjangBayi) {
      validationErrors.panjangBayi = "Field tidak boleh kosong";
    }

    if (!dusun) {
      validationErrors.dusun = "Field tidak boleh kosong";
    }

    if (!rt) {
      validationErrors.rt = "Field tidak boleh kosong";
    }

    if (!rw) {
      validationErrors.rw = "Field tidak boleh kosong";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const confirmed = window.confirm("Anda yakin untuk menyimpan data?");
      if (confirmed) {
        await axios.post("https://api.desasingkalan.com/kelahiran", {
          nama_bayi: namaBayi,
          nama_ayah: namaAyah,
          nama_ibu: namaIbu,
          tempat_lahir: tempatLahir,
          tgl_lahir: tglLahir,
          berat_bayi: beratBayi,
          panjang_bayi: panjangBayi,
          dusun: dusun,
          rt: rt,
          rw: rw,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/kelahiran");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };

  return (
    <div>
      <h1 className="title">Data Kelahiran</h1>
      <h2 className="subtitle">Add Data Kelahiran</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Bayi</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaBayi && "is-danger"}`}
                        value={namaBayi}
                        onChange={(e) => setNamaBayi(e.target.value)}
                        placeholder="Nama Bayi"
                      />
                    </div>
                    {errors.namaBayi && (
                      <p className="help is-danger">{errors.namaBayi}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Ayah</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaAyah && "is-danger"}`}
                        value={namaAyah}
                        onChange={(e) => setNamaAyah(e.target.value)}
                        placeholder="Nama Ayah"
                      />
                    </div>
                    {errors.namaAyah && (
                      <p className="help is-danger">{errors.namaAyah}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Ibu</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaIbu && "is-danger"}`}
                        value={namaIbu}
                        onChange={(e) => setNamaIbu(e.target.value)}
                        placeholder="Nama Ibu"
                      />
                    </div>
                    {errors.namaIbu && (
                      <p className="help is-danger">{errors.namaIbu}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Tempat Lahir</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.tempatLahir && "is-danger"}`}
                        value={tempatLahir}
                        onChange={(e) => setTempatLahir(e.target.value)}
                        placeholder="Tempat Lahir"
                      />
                    </div>
                    {errors.tempatLahir && (
                      <p className="help is-danger">{errors.tempatLahir}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Tgl Lahir</label>
                    <div className="control">
                      <input
                        type="date"
                        className={`input ${errors.tglLahir && "is-danger"}`}
                        value={tglLahir}
                        onChange={(e) => setTglLahir(e.target.value)}
                        placeholder="Tgl Lahir"
                      />
                    </div>
                    {errors.tglLahir && (
                      <p className="help is-danger">{errors.tglLahir}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Berat Bayi (gram)</label>
                    <div className="control">
                      <input
                        type="number"
                        className={`input ${errors.beratBayi && "is-danger"}`}
                        value={beratBayi}
                        onChange={(e) => setBeratBayi(e.target.value)}
                        placeholder="Berat Bayi (gram)"
                      />
                    </div>
                    {errors.beratBayi && (
                      <p className="help is-danger">{errors.beratBayi}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Panjang Bayi (cm)</label>
                    <div className="control">
                      <input
                        type="number"
                        className={`input ${errors.panjangBayi && "is-danger"}`}
                        value={panjangBayi}
                        onChange={(e) => setPanjangBayi(e.target.value)}
                        placeholder="Panjang Bayi (cm)"
                      />
                    </div>
                    {errors.panjangBayi && (
                      <p className="help is-danger">{errors.panjangBayi}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Dusun</label>
                    <div className="control">
                      <div
                        className={`select is-fullwidth ${
                          errors.dusun && "is-danger"
                        }`}
                      >
                        <select
                          value={dusun}
                          onChange={(e) => setDusun(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Dusun
                          </option>
                          <option value="TADO">TADO</option>
                          <option value="SINGKALAN">SINGKALAN</option>
                          <option value="BANJARANYAR">BANJARANYAR</option>
                        </select>
                      </div>
                    </div>
                    {errors.dusun && (
                      <p className="help is-danger">{errors.dusun}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">RT</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rt}
                          onChange={(e) => setRt(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RT
                          </option>
                          {Array.from({ length: 16 }, (_, index) => {
                            const rtNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rtNumber} value={rtNumber}>
                                {rtNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">RW</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rw}
                          onChange={(e) => setRw(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RW
                          </option>
                          {Array.from({ length: 6 }, (_, index) => {
                            const rwNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rwNumber} value={rwNumber}>
                                {rwNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddKelahiran;
