import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  MdPeople,
  MdHome,
  MdFolder,
  MdBabyChangingStation,
  MdLocationOn,
  MdPeopleOutline,
  MdLogout,
  MdPerson,
  MdPregnantWoman

} from "react-icons/md";
import { IconContext } from "react-icons";

import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice.js";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());

    navigate("/");
  };
  return (
    <div>
      <aside className="menu pl-2 has-shadow">
        <p className="menu-label pl-1 mt-2">General</p>
        <ul className="menu-list">
          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/dashboard"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdHome />
              </IconContext.Provider>
              Dashboard
            </NavLink>
          </li>
          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/kependudukan"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdPeople />
              </IconContext.Provider>
              Data Kependudukan
            </NavLink>
          </li>
          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/kematian"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdFolder />
              </IconContext.Provider>
              Data Kematian
            </NavLink>
          </li>

          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/kehamilan"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdPregnantWoman />
              </IconContext.Provider>
              Data Kehamilan
            </NavLink>
          </li>

          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/kelahiran"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdBabyChangingStation />
              </IconContext.Provider>
              Data Kelahiran
            </NavLink>
          </li>
          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/kepindahan"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdLocationOn />
              </IconContext.Provider>
              Data Kepindahan
            </NavLink>
          </li>
          <li style={{ lineHeight: 2 }}>
            <NavLink to={"/pernikahan"}>
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdPeopleOutline />
              </IconContext.Provider>
              Data Pernikahan
            </NavLink>
          </li>
          {/* {user && user.role === "admin" && (
            <li >
              <NavLink to={"/barangkeluar"}>
                <IconContext.Provider
                  value={{ className: "global-class-name mr-2" }}
                >
                  <IoFileTray />
                </IconContext.Provider>
                Barang Keluar
              </NavLink>
            </li>
          )} */}
        </ul>

        {user && user.role === "admin" && (
          <div>
            <p className="menu-label pl-1">Admin</p>
            <ul className="menu-list">
              <li style={{ lineHeight: 2 }}>
                <NavLink to={"/users"}>
                  <IconContext.Provider
                    value={{ className: "global-class-name mr-2" }}
                  >
                    <MdPerson />
                  </IconContext.Provider>
                  Users
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        <p className="menu-label pl-1">Setting</p>
        <ul className="menu-list">
          <li style={{ lineHeight: 2 }}>
            <button onClick={logout} className="button is-white">
              <IconContext.Provider
                value={{ className: "global-class-name mr-2" }}
              >
                <MdLogout />
              </IconContext.Provider>
              Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
