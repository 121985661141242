import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormEditPernikahan = () => {
  const [nikPria, setNikPria] = useState("");
  const [namaPria, setNamaPria] = useState("");
  const [namaWanita, setNamaWanita] = useState("");
  const [nikWanita, setNikWanita] = useState("");
  const [wali, setWali] = useState("");
  const [tglPernikahan, setTglPernikahan] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getPernikahanById = async () => {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/pernikahan/${id}`
        );
        setNikPria(response.data.nik_pria);
        setNamaPria(response.data.nama_pria);
        setNikWanita(response.data.nik_wanita);
        setNamaWanita(response.data.nama_wanita);
        setWali(response.data.wali);
        const tglPernikahanDate = new Date(response.data.tgl_pernikahan);
        const tglPernikahanFormatted = tglPernikahanDate
          .toISOString()
          .split("T")[0];
        setTglPernikahan(tglPernikahanFormatted);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getPernikahanById();
  }, [id]);

  const updatePernikahan = async (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = {};

    if (!nikPria) {
      validationErrors.nikPria = "Field tidak boleh kosong";
    }

    if (!namaPria) {
      validationErrors.namaPria = "Field tidak boleh kosong";
    }

    if (!nikWanita) {
      validationErrors.nikWanita = "Field tidak boleh kosong";
    }

    if (!namaWanita) {
      validationErrors.namaWanita = "Field tidak boleh kosong";
    }

    if (!wali) {
      validationErrors.wali = "Field tidak boleh kosong";
    }

    if (!tglPernikahan) {
      validationErrors.tglPernikahan = "Field tidak boleh kosong";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const confirmed = window.confirm("Anda yakin untuk menyimpan data?");
      if (confirmed) {
        await axios.patch(`https://api.desasingkalan.com/pernikahan/${id}`, {
          nik_pria: nikPria,
          nama_pria: namaPria,
          nik_wanita: nikWanita,
          nama_wanita: namaWanita,
          wali: wali,
          tgl_pernikahan: tglPernikahan,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/pernikahan");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };
  return (
    <div>
      <h1 className="title">Data Pernikahan</h1>
      <h2 className="subtitle">Update data pernikahan</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updatePernikahan}>
              <div className="columns">
                <div className="column">
                  <InputKK
                    label="NIK Pria"
                    value={nikPria}
                    onChange={setNikPria}
                  />
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Pria</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaPria && "is-danger"}`}
                        value={namaPria}
                        onChange={(e) => setNamaPria(e.target.value)}
                        placeholder="Nama Pria"
                      />
                    </div>
                    {errors.namaPria && (
                      <p className="help is-danger">{errors.namaPria}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <InputKK
                    label="NIK Wanita"
                    value={nikWanita}
                    onChange={setNikWanita}
                  />
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama Wanita</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.namaWanita && "is-danger"}`}
                        value={namaWanita}
                        onChange={(e) => setNamaWanita(e.target.value)}
                        placeholder="Nama Wanita"
                      />
                    </div>
                    {errors.namaWanita && (
                      <p className="help is-danger">{errors.namaWanita}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Wali</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.wali && "is-danger"}`}
                        value={wali}
                        onChange={(e) => setWali(e.target.value)}
                        placeholder="Wali"
                      />
                    </div>
                    {errors.wali && (
                      <p className="help is-danger">{errors.wali}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Tgl Pernikahan</label>
                    <div className="control">
                      <input
                        type="date"
                        className={`input ${
                          errors.tglPernikahan && "is-danger"
                        }`}
                        value={tglPernikahan}
                        onChange={(e) => setTglPernikahan(e.target.value)}
                        placeholder="Tgl Pernikahan"
                      />
                    </div>
                    {errors.tglPernikahan && (
                      <p className="help is-danger">{errors.tglPernikahan}</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditPernikahan;
