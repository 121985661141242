import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdOutlineAdd } from "react-icons/md";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);

  const [jumlahPenduduk, setJumlahPenduduk] = useState([]);
  const [jumlahKematian, setJumlahKematian] = useState([]);
  const [dataPekerjaan, setDataPekerjaan] = useState([]);
  const [dataDusun, setDataDusun] = useState([]);
  const [dataJK, setDataJK] = useState([]);
  const [dataKeluarga, setDataKeluarga] = useState([]);
  const [dataUmur, setDataUmur] = useState([]);
  const [dataAgama, setDataAgama] = useState([]);
  const [dataPendTamat, setDataPendTamat] = useState([]);
  const [dataPendSkrg, setDataPendSkrg] = useState([]);
  const [dataKawin, setDataKawin] = useState([]);
  const [dataJamkes, setDataJamkes] = useState([]);
  const [dataYatim, setDataYatim] = useState([]);
  const [dataBantuan, setDataBantuan] = useState([]);

  useEffect(() => {
    getJumlahKematian();
    getJumlahPenduduk();
    getPekerjaan();
    getDusun();
    getJK();
    getKeluarga();
    getUmur();
    getAgama();
    getPendTamat();
    getPendSkrg();
    getKawin();
    getJamkes();
    getYatim();
    getBantuan();
  }, []);

  const getJumlahKematian = async () => {
    const response = await axios.get(
      "https://api.desasingkalan.com/totalkematian"
    );
    setJumlahKematian(response.data);
  };

  const getJumlahPenduduk = async () => {
    const response = await axios.get(
      "https://api.desasingkalan.com/totalpenduduk"
    );
    setJumlahPenduduk(response.data);
  };

  const getPekerjaan = async () => {
    const response = await axios.get("https://api.desasingkalan.com/pekerjaan");
    setDataPekerjaan(response.data);
  };

  const getDusun = async () => {
    const response = await axios.get("https://api.desasingkalan.com/dusun");
    setDataDusun(response.data);
  };

  const getJK = async () => {
    const response = await axios.get("https://api.desasingkalan.com/jk");
    setDataJK(response.data);
  };

  const getKeluarga = async () => {
    const response = await axios.get("https://api.desasingkalan.com/keluarga");
    setDataKeluarga(response.data);
  };

  const getUmur = async () => {
    const response = await axios.get("https://api.desasingkalan.com/umur");
    setDataUmur(response.data);
  };

  const getAgama = async () => {
    const response = await axios.get("https://api.desasingkalan.com/agama");
    setDataAgama(response.data);
  };

  const getPendTamat = async () => {
    const response = await axios.get(
      "https://api.desasingkalan.com/pendidikan-tamat"
    );
    setDataPendTamat(response.data);
  };

  const getPendSkrg = async () => {
    const response = await axios.get(
      "https://api.desasingkalan.com/pendidikan-skrg"
    );
    setDataPendSkrg(response.data);
  };

  const getKawin = async () => {
    const response = await axios.get(
      "https://api.desasingkalan.com/status-kawin"
    );
    setDataKawin(response.data);
  };

  const getJamkes = async () => {
    const response = await axios.get("https://api.desasingkalan.com/jamkes");
    setDataJamkes(response.data);
  };

  const getYatim = async () => {
    const response = await axios.get("https://api.desasingkalan.com/yatim");
    setDataYatim(response.data);
  };

  const getBantuan = async () => {
    const response = await axios.get("https://api.desasingkalan.com/bantuan");
    setDataBantuan(response.data);
  };

  return (
    <div>
      <section className="section is-title-bar p-1 pb-3">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">Dashboard</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section p-2 pb-3">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Jumlah Penduduk</h3>
                      {jumlahPenduduk.map((item, index) => (
                        <tr key={index}>
                          <h1 className="title">{item.total_penduduk}</h1>
                        </tr>
                      ))}
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-primary is-large">
                        <i className="mdi mdi-account-multiple mdi-48px"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">Jumlah KK</h3>
                      {dataKeluarga.map((item, index) => (
                        <tr key={index}>
                          <h1 className="title">{item.total}</h1>
                        </tr>
                      ))}
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-info is-large">
                        <i className="mdi mdi-cart-outline mdi-48px"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section p-2 pb-3">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Pekerjaan</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Pekerjaan</th>
                        <th>Laki-laki</th>
                        <th>Perempuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPekerjaan.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pekerjaan}</td>
                          <td>{item.laki_laki}</td>
                          <td>{item.perempuan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Dusun</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Dusun</th>
                        <th>Laki-laki</th>
                        <th>Perempuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDusun.map((item, index) => (
                        <tr key={index}>
                          <td>{item.dusun}</td>
                          <td>{item.laki_laki}</td>
                          <td>{item.perempuan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Jenis Kelamin</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Jenis Kelamin</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataJK.map((item, index) => (
                        <tr key={index}>
                          <td>{item.jenis_kelamin}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section p-2 pb-3">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Keluarga</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>SHDK</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataKeluarga.map((item, index) => (
                        <tr key={index}>
                          <td>{item.shdk}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Umur</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Umur</th>
                        <th>Laki-laki</th>
                        <th>Perempuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataUmur.map((item, index) => (
                        <tr key={index}>
                          <td>{item.umur}</td>
                          <td>{item.laki_laki}</td>
                          <td>{item.perempuan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Agama</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Agama</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataAgama.map((item, index) => (
                        <tr key={index}>
                          <td>{item.agama}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section p-2 pb-3">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Pendidikan Tamat</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Pendidikan</th>
                        <th>Laki-laki</th>
                        <th>Perempuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPendTamat.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pendidikan_tamat}</td>
                          <td>{item.laki_laki}</td>
                          <td>{item.perempuan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Pendidikan Sekarang</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Pendidikan Sekarang</th>
                        <th>Laki-laki</th>
                        <th>Perempuan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPendSkrg.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pendidikan_skrg}</td>
                          <td>{item.laki_laki}</td>
                          <td>{item.perempuan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Status Perkawinan</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Status Perkawinan</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataKawin.map((item, index) => (
                        <tr key={index}>
                          <td>{item.status_perkawinan}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section is-main-section p-2 pb-3">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Jaminan Kesehatan</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Jaminan Kesehatan</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataJamkes.map((item, index) => (
                        <tr key={index}>
                          <td>{item.jaminan_kesehatan}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Status Yatim</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Status Yatim</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataYatim.map((item, index) => (
                        <tr key={index}>
                          <td>{item.status_yatim}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content card-table-content">
                <h2 className="title is-5 ml-3">Data Bantuan</h2>
                <div className="level is-mobile">
                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Bantuan</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataBantuan.map((item) => (
                        <tr key={item.bantuan}>
                          <td>{item.bantuan}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Welcome;
