import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormEditKepindahan = () => {
  const [nik, setNik] = useState("");
  const [nama, setNama] = useState("");
  const [alamatPindah, setAlamatPindah] = useState("");
  const [tglPindah, setTglPindah] = useState("");
  const [dusun, setDusun] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getKepindahanById = async () => {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/kepindahan/${id}`
        );
        setNik(response.data.nik);
        setNama(response.data.nama);
        setAlamatPindah(response.data.alamat_pindah);
        const tglPindahDate = new Date(response.data.tgl_pindah);
        const tglPindahFormatted = tglPindahDate
          .toISOString()
          .split("T")[0];
        setTglPindah(tglPindahFormatted);
        setDusun(response.data.dusun);
        setRt(response.data.rt);
        setRw(response.data.rw);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getKepindahanById();
  }, [id]);

  const updateKepindahan = async (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = {};

    if (!nik) {
      validationErrors.nik = "Field tidak boleh kosong";
    }

    if (!nama) {
      validationErrors.nama = "Field tidak boleh kosong";
    }

    if (!alamatPindah) {
      validationErrors.alamatPindah = "Field tidak boleh kosong";
    }

    if (!tglPindah) {
      validationErrors.tglPindah = "Field tidak boleh kosong";
    }

    if (!dusun) {
      validationErrors.dusun = "Field tidak boleh kosong";
    }

    if (!rt) {
      validationErrors.rt = "Field tidak boleh kosong";
    }

    if (!rw) {
      validationErrors.rw = "Field tidak boleh kosong";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const confirmed = window.confirm("Anda yakin untuk menyimpan data?");
      if (confirmed) {
        await axios.patch(`https://api.desasingkalan.com/kepindahan/${id}`, {
          nik: nik,
          nama: nama,
          alamat_pindah: alamatPindah,
          tgl_pindah: tglPindah,
          dusun: dusun,
          rt: rt,
          rw: rw,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/kepindahan");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };
  return (
    <div>
      <h1 className="title">Data Kepindahan</h1>
      <h2 className="subtitle">Update data kepindahan</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateKepindahan}>
              <div className="columns">
                <div className="column">
                  <InputKK label="NIK" value={nik} onChange={setNik} />
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${errors.nama && "is-danger"}`}
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        placeholder="Nama"
                      />
                    </div>
                    {errors.nama && (
                      <p className="help is-danger">{errors.nama}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Alamat Pindah</label>
                    <div className="control">
                      <input
                        type="text"
                        className={`input ${
                          errors.alamatPindah && "is-danger"
                        }`}
                        value={alamatPindah}
                        onChange={(e) => setAlamatPindah(e.target.value)}
                        placeholder="Alamat Pindah"
                      />
                    </div>
                    {errors.alamatPindah && (
                      <p className="help is-danger">{errors.alamatPindah}</p>
                    )}
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Tgl Pindah</label>
                    <div className="control">
                      <input
                        type="date"
                        className={`input ${errors.tglPindah && "is-danger"}`}
                        value={tglPindah}
                        onChange={(e) => setTglPindah(e.target.value)}
                        placeholder="Tgl Pindah"
                      />
                    </div>
                    {errors.tglPindah && (
                      <p className="help is-danger">{errors.tglPindah}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Dusun</label>
                <div className="control">
                  <div
                    className={`select is-fullwidth ${
                      errors.dusun && "is-danger"
                    }`}
                  >
                    <select
                      value={dusun}
                      onChange={(e) => setDusun(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Dusun
                      </option>
                      <option value="TADO">TADO</option>
                      <option value="SINGKALAN">SINGKALAN</option>
                      <option value="BANJARANYAR">BANJARANYAR</option>
                    </select>
                  </div>
                </div>
                {errors.dusun && (
                  <p className="help is-danger">{errors.dusun}</p>
                )}
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">RT</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rt}
                          onChange={(e) => setRt(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RT
                          </option>
                          {Array.from({ length: 16 }, (_, index) => {
                            const rtNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rtNumber} value={rtNumber}>
                                {rtNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">RW</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rw}
                          onChange={(e) => setRw(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RW
                          </option>
                          {Array.from({ length: 6 }, (_, index) => {
                            const rwNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rwNumber} value={rwNumber}>
                                {rwNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditKepindahan;
