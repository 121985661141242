import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import InputKK from "./validator/InputKK";

const FormEditKematian = () => {
  const [nik, setNik] = useState("");
  const [nama, setNama] = useState("");
  const [tgl_kematian, setTglKematian] = useState("");
  const [umur, setUmur] = useState("");
  const [penyebab, setPenyebab] = useState("");
  const [dusun, setDusun] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getKematianById = async () => {
      try {
        const response = await axios.get(
          `https://api.desasingkalan.com/kematian/${id}`
        );
        setNik(response.data.nik);
        setNama(response.data.nama);
        setDusun(response.data.dusun);
        setRt(response.data.rt);
        setRw(response.data.rw);
        setUmur(response.data.umur);
        setPenyebab(response.data.penyebab);
        const tglDate = new Date(response.data.tgl_kematian);
        const tglFormatted = tglDate
          .toISOString()
          .split("T")[0];
        setTglKematian(tglFormatted);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getKematianById();
  }, [id]);

  const updateKematian = async (e) => {
    e.preventDefault();
    try {
      const confirmed = window.confirm("Anda yakin untuk menyimpan data?");
      if (confirmed) {
        await axios.patch(`https://api.desasingkalan.com/kematian/${id}`, {
          nik: nik,
          nama: nama,
          tgl_kematian: tgl_kematian,
          umur: umur,
          penyebab: penyebab,
          dusun: dusun,
          rt: rt,
          rw: rw,
        });
        window.alert("Data berhasil disimpan!");
        navigate("/kematian");
      }
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
      window.alert("Data gagal disimpan!");
    }
  };
  return (
    <div>
      <h1 className="title">Data Kematian</h1>
      <h2 className="subtitle">Update data kematian</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateKematian}>
              <div className="columns">
                <div className="column">
                  <InputKK label="NIK" value={nik} onChange={setNik} />
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Nama</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        placeholder="Nama"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Tgl Kematian</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={tgl_kematian}
                        onChange={(e) => setTglKematian(e.target.value)}
                        placeholder="Tgl Kematian"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Umur</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={umur}
                        onChange={(e) => setUmur(e.target.value)}
                        placeholder="Umur"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Penyebab</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={penyebab}
                        onChange={(e) => setPenyebab(e.target.value)}
                        placeholder="Penyebab"
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Dusun</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={dusun}
                          onChange={(e) => setDusun(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Dusun
                          </option>
                          <option value="TADO">TADO</option>
                          <option value="SINGKALAN">SINGKALAN</option>
                          <option value="BANJARANYAR">BANJARANYAR</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">RT</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rt}
                          onChange={(e) => setRt(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RT
                          </option>
                          {Array.from({ length: 16 }, (_, index) => {
                            const rtNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rtNumber} value={rtNumber}>
                                {rtNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">RW</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={rw}
                          onChange={(e) => setRw(e.target.value)}
                        >
                          <option value="" disabled>
                            Select RW
                          </option>
                          {Array.from({ length: 6 }, (_, index) => {
                            const rwNumber = String(index + 1).padStart(3, "0");
                            return (
                              <option key={rwNumber} value={rwNumber}>
                                {rwNumber}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more form fields as needed */}
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditKematian;
